import { phoneNumberMatchingPatternString } from '@wff/mithra/lib/phoneNumber';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { preSignUpFormStyles } from '@wff/ui/homepage/GetStarted/utils';
import { PreSignUpFormData } from '@wff/types';
import 'react-phone-input-2/lib/style.css';
import { clx } from '@wff/ui/css';
import { buildGoTo } from '@wff/mithra/config/platformRoutes';
import { feTrack } from '@wff/mithra/analytics/feTrack';
import { wffTrackableEvents } from '@wff/mithra/analytics/wffTrackableEvents';

const { inputClasses, labelCLasses } = preSignUpFormStyles;

type PersonalInfoProps = {
  formState: PreSignUpFormData;
  updateFormState: (newFormData: Partial<PreSignUpFormData>) => void;
  submitForm: () => Promise<void>;
};

export const PersonalInfo = (props: PersonalInfoProps) => {
  const { formState, updateFormState, submitForm } = props;
  return (
    <form
      className={clx(
        'flex sm:px-12 md:px-4 lg:px-12 px-4 py-5 pt-14 md:flex-row flex-col max-w-[54rem]',
        'items-center gap-x-8 sm:gap-y-6 gap-y-4 justify-center'
      )}
    >
      <div className="flex md:w-1/2 w-full flex-col md:gap-y-10 gap-y-4">
        <p className="text-wffblue-400 ml-2 md:text-3xl font-bold text-2xl">
          <i>finally</i>, finding your ideal flat has never been easier
        </p>
        <div className="sm:flex hidden flex-col gap-y-5">
          <div className="flex gap-x-4">
            <img alt="check icon" src="/assets/check-icon.svg" />
            <div className="flex flex-col">
              <span className="md:text-lg md:leading-none text-base font-bold">
                High-quality flats
              </span>{' '}
              <span className="md:text-base md:leading-tight text-sm font-medium">
                Verified apartments that match your criteria
              </span>
            </div>
          </div>

          <div className="flex gap-x-4">
            <img alt="check icon" src="/assets/check-icon.svg" />
            <div className="flex flex-col">
              <span className="md:text-lg md:leading-none text-base font-bold">
                No additional costs
              </span>

              <span className="md:text-base md:leading-tight text-sm font-medium">
                We do not charge you any hidden fees
              </span>
            </div>
          </div>

          <div className="flex gap-x-4">
            <img alt="check icon" src="/assets/check-icon.svg" />
            <div className="flex flex-col">
              <span className="md:text-lg md:leading-none text-base font-bold">
                Reliable support
              </span>{' '}
              <span className="md:text-base md:leading-tight text-sm font-medium">
                Around the clock support from real humans in your WhatsApp and
                email
              </span>
            </div>
          </div>
        </div>
        <div className="hidden md:flex flex-wrap gap-x-8 justify-center wrap">
          <img
            alt="trust pilot logo"
            className="h-7"
            src="/assets/trust-pilot-logo.svg"
          />

          <div className="flex mt-2 flex-col">
            <div className="flex gap-x-0.5 items-center">
              {Array(5)
                .fill(0)
                .map((v, i) => (
                  <div className="p-1 bg-emerald-500" key={clx('star', v, i)}>
                    <img
                      className="w-4 h-4"
                      alt="full-trust-piolt-star"
                      src="/assets/trustpilot-star.svg"
                    />
                  </div>
                ))}
            </div>
            <p className="text-xl mt-1 font-bold">Excellent</p>
          </div>
        </div>
      </div>
      <div
        className={clx(
          'flex md:w-1/2 w-full sm:border border-0 border-[#999999] h-fit sm:p-6 p-2',
          'rounded-xl items-center sm:gap-y-2 gap-y-1 flex-col'
        )}
      >
        <div className="flex gap-x-3">
          <div className="w-full">
            <label className={labelCLasses} htmlFor="preSignFirstName">
              First name
            </label>
            <input
              required
              type="text"
              value={formState.firstName}
              minLength={2}
              id="preSignFirstName"
              name="email"
              className={`w-full ${inputClasses}`}
              onChange={(e) => updateFormState({ firstName: e.target.value })}
            />
          </div>

          <div className="w-full">
            <label className={labelCLasses} htmlFor="preSignLastName">
              Last name
            </label>
            <input
              required
              type="text"
              minLength={2}
              value={formState.lastName}
              id="preSignLastName"
              name="email"
              className={`w-full ${inputClasses}`}
              onChange={(e) => updateFormState({ lastName: e.target.value })}
            />
          </div>
        </div>
        <div className="w-full">
          <label className={labelCLasses} htmlFor="preSignEmail">
            Email adress
          </label>
          <input
            required
            type="email"
            value={formState.email}
            id="preSignEmail"
            name="email"
            className={`w-full ${inputClasses}`}
            onChange={(e) => updateFormState({ email: e.target.value })}
          />
        </div>

        <div className="w-full">
          <label className={labelCLasses}>Phone Number</label>

          <PhoneInput
            inputStyle={{
              border: '1px solid #999999',
              width: '100%',
              borderRadius: '0.5rem',
            }}
            inputClass="!py-1 sm:!py-6 focus:ring-0 !text-sm sm:!text-base"
            countryCodeEditable={false}
            enableSearch={true}
            autocompleteSearch={true}
            disableSearchIcon={true}
            containerStyle={{ width: '100%' }}
            country={'gb'}
            value={formState.phonenumber}
            onChange={(value, country: CountryData) => {
              updateFormState({ phonenumber: value });
            }}
            inputProps={{
              required: true,
              name: 'phonenumber',
              id: 'phonenumber',
              pattern: phoneNumberMatchingPatternString,
            }}
          />
        </div>
        <p className="sm:text-sm mt-2 sm:mt-0 justify-self-end text-xs text-[#999999]">
          By continuing, you are agreeing with our{' '}
          <a href="https://www.wefindflats.com/privacy" className="underline">
            privacy policy
          </a>
          .
        </p>
        <button
          type="submit"
          className={clx(
            'mt-3 sm:w-52 w-36 py-3',
            'font-bold text-sm rounded-xl text-white bg-wffblue-400',
            'disabled:bg-[#999999] disabled:cursor-not-allowed disabled:opacity-50'
          )}
          onClick={async (e: any) => {
            const target = e.target as HTMLButtonElement;
            const form = e.target.form as HTMLFormElement;
            if (!form) {
              console.error('Form not found');
              return;
            }

            target.disabled = true;
            const invalidFields = form.querySelectorAll(':invalid');

            // clear error styles from previous submission
            const allFields = form.querySelectorAll('input, select, textarea');

            if (invalidFields.length) {
              // add error styles to invalid fields

              target.disabled = false;
              return;
            }

            await submitForm();
            feTrack(wffTrackableEvents.flatSeeker.signupCompleted);
            const updatedUrl = buildGoTo(window.location.pathname, {
              search: { signup: 'completed' },
              currentUrl: window.location.href,
            });
            window.history.pushState({}, '', updatedUrl);
            target.disabled = false;
          }}
        >
          Get Started
        </button>
      </div>

      <div className="flex md:hidden flex-wrap gap-x-8 justify-center wrap">
        <img
          alt="trust pilot logo"
          className="h-7"
          src="/assets/trust-pilot-logo.svg"
        />

        <div className="flex mt-2 flex-col">
          <div className="flex gap-x-0.5 items-center">
            {Array(5)
              .fill(0)
              .map((v, i) => (
                <div className="p-1 bg-emerald-500" key={clx('star', v, i)}>
                  <img
                    className="w-4 h-4"
                    alt="full-trust-piolt-star"
                    src="/assets/trustpilot-star.svg"
                  />
                </div>
              ))}
          </div>
          <p className="text-xl mt-1 font-bold">Excellent</p>
        </div>
      </div>
    </form>
  );
};
