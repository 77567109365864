import { clx } from '@wff/ui/css';
import React from 'react';
import { forwardRef } from 'react';
type InputPropsBase = {
  name: string;
  id: string;
  type: 'text' | 'email' | 'password' | 'number' | 'hidden' | 'date' | 'time' | 'url'
  required?: boolean;
  placeholder?: string;
  pattern?: string;
  title?: string;
  minLength?: number;
  min?: number | string;
  max?: number | string;
  label?: string;
  disabled?: boolean;
  onBlur?: (e: any) => void;
  wide?: boolean;
  sm?: boolean;
  testId?: string;
  error?: string;
  xs?: boolean;
};

type InputProps = InputPropsBase &
  (
    | {
        // This component is controlled by You/react
        onChange: React.InputHTMLAttributes<HTMLInputElement>['onChange'];
        /** You have to pass a value when passing onChange */
        value: string;
        defaultValue?: never;
      }
    | {
        // This component is uncontrolled, DOM manges the value
        /** You have to pass a default value when the component is uncontrolled */
        defaultValue: string;
        value?: never;
        onChange?: never;
      }
  );

export const TextInput: React.FC<InputProps> = forwardRef(function TextInput(
  props: InputProps,
  ref
) {
  const {
    name,
    id,
    type,
    placeholder,
    required = false,
    pattern,
    title,
    minLength,
    min,
    label,
    disabled = false,
    onBlur,
    wide = false,
    sm = false,
    xs = false,
    testId,
    error,
    max,
  } = props;

  const value = props['value'];
  const defaultValue = props['defaultValue'];
  const onChange = props['onChange'];

  const inputClasses = clx(
    'input input-bordered input-primary w-full border-gray-300',
    'focus:outline-0 focus:ring-1 focus:ring-primary',
    'focus-within:outline-0 focus-within:ring-1 focus-within:ring-primary',
    sm ? 'text-sm' : 'text-base',
    sm && 'input-sm',
    error && 'border-red-500',
    xs && 'text-xs'
  );

  const labelClasses = clx(
    'block text-base sm:text-sm font-medium',
    sm && 'text-sm'
  );
  return (
    <div
      className={clx(
        'flex font-inter w-full flex-col gap-y-2',
        wide || 'sm:max-w-[16rem] max-w-auto'
      )}
    >
      <label htmlFor={id} className={labelClasses}>
        {label}
        {required && label && (
          <span className="text-red-500 inline-block">*</span>
        )}
      </label>

      <input
        disabled={disabled}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        required={required}
        id={id}
        pattern={pattern}
        title={title}
        minLength={minLength}
        min={min}
        max={max}
        className={inputClasses}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref as React.MutableRefObject<HTMLInputElement>}
        data-testid={testId}
      />

      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
});
