import { WffCity } from '@wff/types';

export const CITIES = [
  ...Object.values(WffCity).map((city) => {
    return {
      title: city,
      value: city === WffCity.London ? 'GB' : 'DE',
    };
  }),
];
